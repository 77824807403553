import React from 'react'
import PropTypes from 'prop-types'
import Spacer from '../../Spacer'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import RichText from '../../RichText'
import { Heading2 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'

const Article = ({ title, content }) => (
  <>
    <Spacer size={[128, 290]} />

    <Container>
      <Grid>
        <GridItem
          tabletP={12}
          tabletPStart={3}
          tabletL={10}
          tabletLStart={4}
          desk={8}
          deskStart={5}
        >
          <Heading2 as="h1">
            <AnimateSplitText>{title}</AnimateSplitText>
          </Heading2>

          <Spacer size={[30, 50]} />

          <RichText content={content} />
        </GridItem>
      </Grid>

      <Spacer size={[60, 160]} />
    </Container>
  </>
)

Article.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
}

export default Article
