import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Article from '../components/StandardContent/Article'

const StandardContent = ({ data }) => {
  const { seoTitle, seoDescriptions, slug, title, content } =
    data.contentfulStandardContent

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />
      <Article title={title} content={content} />
    </>
  )
}

export default StandardContent

export const pageQuery = graphql`
  query StandardContent($slug: String!) {
    contentfulStandardContent(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      title
      content {
        raw
        references {
          ...ContentfulRichTextReferences
        }
      }
    }
  }
`
